import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {
  //   path: 'user/:user',
  //   loadChildren: () =>
  //     import('./modules/personal/personal.module').then(
  //       (m) => m.PersonalModule
  //     ),
  // },
  {
    path: 'c/unionplastics/:user',
    loadChildren: () =>
      import('./modules/company/unionplastics/unionplastics.module').then(
        (m) => m.UnionplasticsModule
      ),
  },
  {
    path: 'c/datamark/:user',
    loadChildren: () =>
      import('./modules/company/datamark/datamark.module').then(
        (m) => m.DatamarkModule
      ),
  },
  {
    path: 'c/kims/:user',
    loadChildren: () =>
      import('./modules/company/kims/kims.module').then((m) => m.KimsModule),
  },
  {
    path: 'c/datamark/:user',
    loadChildren: () =>
      import('./modules/datamark/datamark.module').then(
        (m) => m.DatamarkModule
      ),
  },
  {
    path: 'c/aljabal/:user',
    loadChildren: () =>
      import('./modules/company/aljabal/aljabal.module').then(
        (m) => m.AljabalModule
      ),
  },
  {
    path: 'c/lulu/:user',
    loadChildren: () =>
      import('./modules/company/lulu/lulu.module').then((m) => m.LuluModule),
  },
  {
    path: 'c/saara/:user',
    loadChildren: () =>
      import('./modules/company/saara/saara.module').then((m) => m.SaaraModule),
  },
  {
    path: 'c/almuftah/:user',
    loadChildren: () =>
      import('./modules/company/almuftah/almuftah.module').then(
        (m) => m.AlmuftahModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
